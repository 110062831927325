import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedstate from 'vuex-persistedstate';
import getters from './getters';
import user from './modules/user';
import dict from './modules/dict';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dict,
  },
  getters,
  plugins: [
    createPersistedstate({
      key: 'user', // 存数据的key名   自定义的  要有语义化
      // paths: ['loginModule'] // 要把那些模块加入缓存
    }),
  ],
});
