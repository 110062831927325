<template>
    <div>
        <!-- @close="closeLogin()" -->
        <el-dialog :visible.sync="showXy">
            <pdf src="/xxxy.pdf"></pdf>
            <!-- <div v-html="/xxxy.pdf"> <div> -->
        </el-dialog>
        <el-dialog :visible.sync="showLogin" :show-close="false" :close-on-click-modal="true" class="loginDialog"
            width="45rem" append-to-body @close="closeDialog">
            <div class="login-box" v-loading="loading">
                <div class="warp">
                    <el-tooltip :value="showLogin" :content="loginMode ? '手机号登陆' : '微信扫码登陆'" :hide-after="0"
                        :manual="true" placement="left">
                        <div class="code" :style="`background:url(${loginMode ? code2 : code});`"
                            @click="loginMode = !loginMode"></div>
                    </el-tooltip>
                    <div v-if="loginMode == 0">
                        <div class="fw_blod fs_24 flex just_center login_title">
                            {{ isRegister ? "手机短信注册" : "手机验证登陆" }}
                        </div>
                        <el-form :model="userInfos" :rules="rules" ref="loginForm">
                            <el-form-item prop="phoneNumber">
                                <el-input placeholder="请输入手机号" v-model="userInfos.phoneNumber"></el-input>
                            </el-form-item>
                            <el-form-item prop="smsCode">
                                <el-input placeholder="请输入验证码" v-model="userInfos.smsCode">
                                    <template slot="append">
                                        <el-button type="primary" @click="sendMessage">
                                            {{ codeDuration ? codeDuration + "s" : "发送验证码" }}
                                        </el-button>
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="
                    width: 100%;
                    margin-bottom: 10px;
                    padding: 14px 20px;
                    font-size: 17px;
                  " @click="submit">
                                    {{ isRegister ? "注册" : "登录" }}
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-else>
                        <div class="fw_blod fs_24 flex just_center login_title">
                            扫码登录
                        </div>
                        <div style="margin-top: -12px; text-align: center; color: #000" class="fs_14 opacity_4">
                            请使用微信扫码二维码登录
                        </div>
                        <div class="wechart">
                            <img width="220px" :src="'data:image/jpg;base64,' + url" />
                            <!-- <qriously :value="url" :size="160"></qriously> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="remember flex">
                <el-checkbox v-model="remember"></el-checkbox>
                <div class="m_l_10 pointer" @click="remember = !remember">
                    您注册即同意用户服务协议、隐私政策和授权许可协议，如您成为会员，成为会员即视为同意
                    <a style="color: #1c7deb" @click="showXy = true">会员服务协议。</a>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import code from "@/assets/二维码.png";
    import code2 from "@/assets/手机.png";
    import {
        regIphone
    } from "@/utils/reg";
    import pdf from "vue-pdf";
    import {
        getLoginSmsCode,
        getRegisterSmsCode,
        register,
        getCodeImg,
        wxQr,
        getWxQrStatus,
    } from "@/api/login";
    export default {
        data: () => {
            var validatePhone = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("手机号不能为空"));
                } else if (!regIphone.test(value)) {
                    callback(new Error("请输入正确手机号"));
                } else {
                    callback();
                }
            };
            return {
                url: "",
                scene: "",
                showXy: false,
                showLogin: false,
                isRegister: false,
                showPhoneDialog: false,
                loading: false,
                code,
                code2,
                loginMode: 0,
                codeDuration: 0,

                timer: "",
                lock: true,
                qrdata: "", //二维码信息
                remember: true,
                userInfos: {
                    phoneNumber: "",
                    smsCode: "",
                    uuid: "",
                },
                rules: {
                    phoneNumber: [{
                        required: true,
                        validator: validatePhone,
                        trigger: "blur",
                    }, ],
                    smsCode: [{
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    }, ],
                },
            };
        },
        components: {
            pdf,
        },
        watch: {
            showLogin: {
                handler(val) {
                    if (val) {

                        if (this.loginMode) {
                            this.getCode();
                            //开始定时刷新状态
                            this.refreshCode();
                            console.log("开始定时刷新状态");
                        } else {
                            //关闭定时刷新
                            console.log("关闭定时刷新");
                            clearInterval(this.timer);
                        }
                    } else {
                        //关闭定时刷新
                        console.log("关闭定时刷新");
                        clearInterval(this.timer);
                    }
                },
                immediate: true,
            },
            loginMode(val) {
                if (val) {
                    this.getCode();
                    this.refreshCode();
                    //开始定时刷新状态
                    console.log("开始定时刷新状态");
                } else {
                    //关闭定时刷新
                    console.log("关闭定时刷新");
                    clearInterval(this.timer);
                }
            },
        },

        methods: {
            closeDialog() {
                this.loginMode = 0;
            },
            //获取微信二维码
            getCode() {
                wxQr().then((res) => {
                    this.url = res.data.wxQr;
                    this.scene = res.data.scene;
                });
            },
            //刷新登录二维码状态
            refreshCode() {
                //2秒刷新一次
                this.timer = setInterval(() => {
                    getWxQrStatus(this.scene).then((res) => {
                        if (res.data.status == 2) {
                            clearInterval(this.timer);
                            this.$message.success("登录成功");
                            this.$store.dispatch("SetToken", res.data.token);
                            this.$store.dispatch("GetInfo");
                            this.showLogin = false;
                        }
                        console.log(res);
                    });
                }, 2000);
            },
            open(type) {
                this.isRegister = type;
                this.showLogin = true;
                if (!this.loginMode) {
                    this.$nextTick(() => {
                        this.$refs.loginForm.resetFields();
                    });
                }
            },
            submit() {
                if (!this.remember) {
                    this.$message.warning("请阅读并同意相关协议");
                    return false;
                }
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        //登录or注册
                        if (this.isRegister) {
                            register({
                                code: this.userInfos.smsCode,
                                name: this.userInfos.phoneNumber,
                                password: this.userInfos.phoneNumber,
                                phoneNumber: this.userInfos.phoneNumber,
                                uuid: this.userInfos.uuid,
                            }).then((res) => {
                                if (res.code == 200) {
                                    this.$message.success("注册成功");
                                    clearInterval(this.timer);
                                    this.codeDuration = 0;
                                    this.isRegister = false;
                                    this.userInfos.smsCode = "";

                                    //登录过程

                                    this.$store.dispatch("SetToken", res.token).then((res) => {
                                        this.$store.dispatch("GetInfo");
                                        this.showLogin = false;
                                    });
                                }
                            });
                        } else {
                            this.$store.dispatch("Login", this.userInfos).then((res) => {
                                this.$message.success("登录成功");
                                this.$store.dispatch("GetInfo");
                                this.showLogin = false;
                            });
                        }
                    }
                });
            },

            sendMessage() {
                //倒计时结束开始操作
                if (this.codeDuration != 0) {
                    return;
                }
                if (!regIphone.test(this.userInfos.phoneNumber)) {
                    this.$message.warning("请输入正确手机号");
                    return false;
                }
                //发送注册验证码or登录验证码
                if (this.isRegister) {
                    getRegisterSmsCode(this.userInfos.phoneNumber).then((res) => {
                        if (res.code == 200) {
                            this.userInfos.uuid = res.uuid;
                            this.$message.success("发送成功");
                            this.codeDuration = 60;
                            this.timer = setInterval(() => {
                                this.codeDuration--;
                                if (this.codeDuration == 0) {
                                    clearInterval(this.timer);
                                }
                            }, 1000);
                        }
                    });
                } else {
                    getLoginSmsCode(this.userInfos.phoneNumber).then((res) => {
                        if (res.code == 200) {
                            this.$message.success("发送成功");
                            this.userInfos.uuid = res.uuid;
                            this.codeDuration = 60;
                            this.timer = setInterval(() => {
                                this.codeDuration--;
                                if (this.codeDuration == 0) {
                                    clearInterval(this.timer);
                                }
                            }, 1000);
                        }
                    });
                }
            },
        },

        mounted() {},
    };
</script>
<style>
    .el-tooltip__popper {
        display: none !important;
        background-color: #f0f6ff !important;
        color: #1974d0 !important;

        /* left: 680px !important; */
    }

    .popper__arrow {
        border-left-color: #f0f6ff !important;
    }

    .popper__arrow::after {
        border-left-color: #f0f6ff !important;
    }
</style>
<style scoped lang="scss">
    .code {
        position: absolute;
        right: 30px;
        top: 70px;
        height: 50px;
        width: 50px;
        cursor: pointer;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        border-radius: 0.8rem;
    }

    .loginDialog {
        .wechart {
            display: flex;
            justify-content: center;
            padding: 20px;
        }

        .remember {
            background-color: #f5f5f5;
            width: 100%;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 27px;
            box-sizing: border-box;
            color: #878c91;
            font-size: 12px;
            line-height: 20px;
        }

        .login_title {
            margin-top: 80px;
            margin-bottom: 25px;
        }

        ::v-deep .el-dialog {
            background-color: transparent;
            box-shadow: none;
        }

        ::v-deep .el-dialog__headerbtn {
            right: 0px;
            top: 30px;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 50%;
            width: 25px;
            height: 25px;
        }

        .login-box {
            color: #0f4444;
            background-color: #fff;

            border-top-left-radius: 0.8rem;
            border-top-right-radius: 0.8rem;
            display: flex;

            // padding-bottom: 1px;
            .warp {
                width: 74%;
                // padding: 1rem 0;
                // padding-top: 2.5rem;

                margin: 0 auto;

                .title {
                    font-family: YouSheBiaoTiHei;
                    font-weight: 400;
                    font-size: 2.8rem;
                    letter-spacing: 0.25rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 3.6rem;
                        padding: 0 0.6rem;
                    }
                }
            }
        }
    }
</style>