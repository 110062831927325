import {
    smsLogin,
    getInfo,
    logout
} from "@/api/login";
import {
    set,
    get,
    clear
} from "@/utils/local";
const user = {
    state: {
        token: "",
        name: "",
        avatar: "",
        roles: [],
        role: [],
        defRole: [],
        permissions: [],
        userInfo: {},
        menus: [],
        temporary: "",
        configs: ''
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },

        SET_MENUS: (state, menus) => {
            state.menus = menus;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_ROLE_CURRENT: (state, role) => {
            state.role = role;
        },

        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo;
        },

        SET_NULL: (state) => {
            Object.keys(state).map((item) => {
                state[item] = [];
            });
        },
        SET_CONFIG: (state, configs) => {
            state.configs = configs;
        }
    },

    actions: {
        //保存配置
        SetConfig({
            commit
        }, configs) {
            commit("SET_CONFIG", configs);
        },
        // 登录
        Login({
            commit
        }, userInfo) {
            return new Promise((resolve, reject) => {
                smsLogin(userInfo)
                    .then((res) => {
                        commit("SET_TOKEN", res.token);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        SetToken({
            commit
        }, token) {
            commit("SET_TOKEN", token);
        },
        // 获取用户信息
        GetInfo({
            commit,
            state
        }) {
            return new Promise((resolve, reject) => {
                getInfo()
                    .then((res) => {
                        commit("SET_USERINFO", res.user);

                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        //设置当前用户角色
        SetRole({
            commit,
            state
        }, role) {
            //当用户是校管理员时判断是分校管理员还是总管理员
            if (role.role_key == "school_man") {
                if (role.stage_id) {
                    role.is_branch = true;
                } else {
                    role.is_branch = false;
                }
            }
            commit("SET_ROLE_CURRENT", role);

            //获取角色菜单
            return new Promise((resolve, reject) => {
                getMenus(role.role_key)
                    .then((res) => {
                        commit("SET_MENUS", res.data);
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        //获取用角色
        GetRole({
            commit,
            state
        }) {
            return new Promise((resolve, reject) => {
                getRole()
                    .then((res) => {
                        commit("SET_ROLES", res.data);
                        //设置用户默认角色
                        if (res.data.length > 0) {
                            let defRole = res.data.filter((item) => item.def == true);
                            if (defRole.length > 0) {
                                commit("SET_ROLE_CURRENT", defRole[0]);
                            } else {
                                commit("SET_ROLE_CURRENT", res.data[0]);
                            }
                            resolve(res);
                        } else {
                            this.$modal.msgWarning("当前用户无角色，请联系管理员");
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        //更新用户信息
        UpdateInfo({
            commit
        }, userInfo) {
            commit("SET_USERINFO", userInfo);
        },

        // 退出系统
        LogOut({
            commit,
            state
        }) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit("SET_NULL", []);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
};

export default user;