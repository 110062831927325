export const set = (key, val) => {
  return localStorage.setItem(key, JSON.stringify(val));
};

export const get = (key) => {
  if (localStorage.getItem(key)) return JSON.parse(localStorage.getItem(key));
  else return null;
};

export const clear = (key) => {
  if (key) localStorage.removeItem(key);
  else localStorage.clear();
};

export const sessionSet = (key, val) => {
  if (key != null && val != null) {
    return sessionStorage.setItem(key, JSON.stringify(val));
  }
};

export const sessionGet = (key) => {
  if (sessionStorage.getItem(key))
    return JSON.parse(sessionStorage.getItem(key));
  else return null;
};

export const sessionClear = (key) => {
  if (key) sessionStorage.removeItem(key);
  else sessionStorage.clear();
};

export const getToken = () => {
  if (localStorage.getItem('user')) {
    let res = JSON.parse(localStorage.getItem('user'));
    return res.user.token;
  } else return null;
};
