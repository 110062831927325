<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters(["token"]),
  },
};
</script>

<style>
body,
html {
  font-size: 10px;
  margin: 0 auto;
}
* {
  margin: 0;
  padding: 0;
}
</style>
