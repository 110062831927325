import request from "@/utils/request";

// 获取空间应用
export function getAppByspace(spaceCode) {
    return request({
        url: "/baseAppInfo/getAppByspace?spaceCode=" + spaceCode,
        method: "get",
    });
}
//获取新闻资讯
export function getNewsList(data) {
    return request({
        url: "/portal/news/list",
        method: "get",
        params: data,
    });
}

// 查询邮轮分组信息列表
export function listCityAnon(query) {
    return request({
        url: "/biz/city/anon/list",
        method: "get",
        params: query,
    });
}

// 查询邮轮月班次列表
export function getShipMonthSchedulingList(query) {
    return request({
        url: "/biz/scheduling/anon/getMonthList",
        method: "get",
        params: query,
    });
}

// 查询首页轮播图
export function getBannerShipList(query) {
    return request({
        url: "/biz/bannerShip/anon/getBannerShipList",
        method: "get",
        params: query,
    });
}

// 查询首页轮播图
export function getShipGroupDataList(query) {
    return request({
        url: "/biz/group/anon/getShipGroupDataList",
        method: "get",
        params: query,
    });
}

// 查询班次列表
export function getSchedulingPageList(query) {
    return request({
        url: "/biz/scheduling/app/getSchedulingPageList",
        method: "get",
        params: query,
    });
}

//船票预售列表
export function getShipPageList(query) {
    return request({
        url: "/biz/ship/site/getShipPageList",
        method: "get",
        params: query,
    });
}

//客服接口
export function getCustomer() {
    return request({
        url: "/system/customer/site/getList",
        method: "get",
    });
}

//查询游轮订单消息
export function getOrderMsgList() {
    return request({
        url: "/biz/order/site/getOrderMsgList",
        method: "get",
    });
}
//新增联系人
export function addContactUser(query) {
    return request({
        url: "/biz/contactUser",
        method: "post",
        data: query,
    });
}
//删除旅客
export function delContactUser(query) {
    return request({
        url: "/biz/contactUser/" + query,
        method: "delete",
    });
}
//修改联系人
export function updateContactUser(query) {
    return request({
        url: "/biz/contactUser",
        method: "put",
        data: query,
    });
}
//查询联系人信息
export function getContactUser(query) {
    return request({
        url: "/biz/contactUser/getMyList",
        method: "get",
        params: query,
    });
}

//获取航线
export function getNavRoute() {
    return request({
        url: "/biz/navRoute/site/getList",
        method: "get",
    });
}
//获取配置
export function getConfig() {
    return request({
        url: "/system/sitConfig/getList",
        method: "get",
    });
}