var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.options != null)?_c('div',[_vm._l((_vm.options.filter((el) =>
      _vm.values.includes(el.value)
    )),function(item,index){return [(_vm.values.includes(item.value))?[(item.raw.listClass == 'default' || item.raw.listClass == '')?[(_vm.myTag)?[(_vm.myTag == 'slash')?_c('span',[_vm._v(" "+_vm._s(item.label)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.values.length > 1 &&
                index !==
                  _vm.options.filter((el) => _vm.values.includes(el.value)).length - 1
              ),expression:"\n                values.length > 1 &&\n                index !==\n                  options.filter((el) => values.includes(el.value)).length - 1\n              "}]},[_vm._v(" / ")])]):_vm._e(),(_vm.myTag == 'phrases')?_c('span',[_vm._v(" "+_vm._s(item.label)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.values.length > 1 &&
                index !==
                  _vm.options.filter((el) => _vm.values.includes(el.value)).length - 1
              ),expression:"\n                values.length > 1 &&\n                index !==\n                  options.filter((el) => values.includes(el.value)).length - 1\n              "}]},[_vm._v(" 、 ")])]):_vm._e()]:[(_vm.tagType)?_c('el-tag',{key:item.value,class:item.raw.cssClass,attrs:{"size":"small","index":index}},[_vm._v(" "+_vm._s(item.label.length > 7 ? item.label.substring(0, 7) + '...' : item.label)+" ")]):_c('span',{key:item.value,class:item.raw.cssClass,attrs:{"index":index}},[_vm._v(" "+_vm._s(item.label)+" ")])]]:_c('el-tag',{key:item.value,class:item.raw.cssClass,attrs:{"disable-transitions":true,"index":index,"type":item.raw.listClass == 'primary' ? '' : item.raw.listClass}},[_vm._v(" "+_vm._s(item.label)+" ")])]:_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }