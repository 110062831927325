const getters = {
    token: (state) => state.user.token,
    configs: (state) => state.user.configs,
    roles: (state) => state.user.roles,
    role: (state) => state.user.role,
    defRole: (state) => state.user.defRole,
    dict: (state) => state.dict.dict,
    menus: (state) => state.user.menus,
    permissions: (state) => state.user.permissions,
    userInfo: (state) => state.user.userInfo,
    permission_routes: (state) => state.permission.routes,
};
export default getters;