<template>
    <div v-loading="loading">
        <Header></Header>
        <!-- 订单查询窗口-->
        <MyDialog dialogWidth="600px" title="订单查询" @close="showDialog = false" ref="dialog" v-if="showDialog"
            @confirm="toOrder">
            <el-form class="ordermsg" label-width="100px" :model="searchForm" :rules="searchRules" ref="orderForm">
                <!-- <div class="tit">订单查询</div> -->
                <el-form-item label="姓名:" prop="userName">
                    <el-input style="width: 80%" v-model="searchForm.userName"></el-input>
                </el-form-item>
                <el-form-item label="手机号:" prop="phoneNum">
                    <el-input style="width: 80%" v-model="searchForm.phoneNum"></el-input>
                </el-form-item>
            </el-form>
        </MyDialog>
        <!-- 侧边悬浮框 -->
        <div class="flow">
            <div class="balloon">
                <div class="title fs_21">
                    在线
                    <br />
                    客服
                </div>
                <div class="box fs_14 flex just_center" v-for="item in customers">
                    <el-popover placement="left-start" title="" width="300" trigger="hover">
                        <div class="card flex just_between">
                            <div>
                                <div class="fs_24 fw_blod m_b_5">{{ item.name }}</div>
                                <div class="m_t_10">
                                    手机：
                                    <span class="fw_blod">{{ item.phoneNumber }}</span>
                                    (微信号)
                                </div>
                                <div class="m_t_10">官网客服，请放心添加好友！</div>
                            </div>
                            <div class="m_l_10">
                                <img :src="baseUrl + item.wxImage" style="height: 90px" />
                            </div>
                        </div>
                        <div slot="reference">
                            <img src="../../assets/客服@2x.png" />
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </el-popover>
                </div>
            </div>

            <div class="outer fs_14 flex just_center">
                <el-popover placement="left-start" title="" width="" trigger="hover">
                    <div style="margin-left: 10px">
                        <img src="../../assets/gh_30d281385a4e_344.jpg" style="width: 120px" />
                    </div>
                    <div slot="reference" class="flex flex_column aligin_center">
                        <img src="../../assets/小程序.png" />
                        <div class="m_t_5">小程序</div>
                    </div>
                </el-popover>
            </div>

            <div class="outer fs_14 flex just_center" @click="showDialog = true">
                <img src="../../assets/ico_院长工作台_订单查询.png" />
                <div class="m_t_5">订单查询</div>
            </div>
            <div class="outer fs_14 flex just_center" @click="top">
                <img src="../../assets/向上箭头.png" />
            </div>
        </div>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from "@/views/layout/Header";
    import Footer from "@/views/layout/Footer";
    import MyDialog from "@/components/MyDialog";
    import {
        regIphone
    } from "@/utils/reg";
    import {
        getOrderPageList
    } from "@/api/order";
    import {
        getCustomer,
        getConfig
    } from "@/api/home/index";
    export default {
        name: "HomeLayout",
        components: {
            Header,
            Footer,
            MyDialog,
        },
        data: () => {
            var validatePhone = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("手机号不能为空"));
                } else if (!regIphone.test(value)) {
                    callback(new Error("请输入正确手机号"));
                } else {
                    callback();
                }
            };
            return {
                showDialog: false,
                loading: false,
                baseUrl: process.env.VUE_APP_BASE_API,
                searchForm: {
                    userName: "",
                    phoneNum: "",
                },
                customers: [],
                searchRules: {
                    phoneNum: [{
                        required: true,
                        validator: validatePhone,
                        trigger: "blur",
                    }, ],
                    userName: [{
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    }, ],
                },
            };
        },
        created() {
            getConfig().then((res) => {
                this.$store.dispatch("SetConfig", res.rows)
            })
            getCustomer().then((res) => {
                this.customers = res.data;
            });
        },
        methods: {
            top() {
                this.topScroll();
            },
            toOrder() {
                this.loading = true;
                this.$refs.orderForm.validate((valid) => {
                    if (valid) {
                        getOrderPageList(this.searchForm).then((res) => {
                            if (res.code == 200) {
                                if (res.rows.length > 0) {
                                    this.showDialog = false;
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.$router.push({
                                            query: this.searchForm,
                                            path: "/exitOrder",
                                        });
                                    }, 500);
                                } else {
                                    this.$message.warning("没有相关订单，请联系客服处理");
                                    this.loading = false;
                                }
                            }
                        });
                    } else {
                        this.loading = false;
                    }
                });
                //$router.push('/personal')
            },
        },
    };
</script>
<style>
    .mypop {
        margin-right: 40px !important;
        color: #333333 !important;
        font-size: 12px !important;
    }
</style>
<style scoped lang="scss">
    .flow {
        position: fixed;
        z-index: 9999;
        right: 0px;
        top: 34%;
        width: 72px;
        color: #fff;

        .balloon {
            display: flex;
            flex-direction: column;
            align-items: center;

            background: #1874d0;
            border: 1px solid #0065ca;
            box-shadow: 0px 7px 6px 0px rgba(24, 116, 208, 0.1);
            border-radius: 10px;
            padding: 0 0 10px 0;

            .box {
                cursor: pointer;
                width: 60px;
                border-radius: 6px;
                margin-bottom: 8px;
                text-align: center;
                padding: 5px 0 8px;

                img {
                    width: 32px;
                }

                flex-direction: column;
                display: flex;
                align-items: center;

                .name {
                    font-size: 13px;
                    line-height: 1;
                    color: rgba(255, 255, 255, 0.6);
                }
            }

            .title {
                width: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px 0;
                color: #fff;
                font-weight: bold;

                &.fs_21 {
                    font-size: 1.6rem;
                }
            }

            .box:hover {
                background-color: #1467c8;

                .name {
                    color: #fff;
                }
            }
        }

        .outer {
            background: #1874d0;
            cursor: pointer;
            width: 72px;
            border-radius: 6px;
            height: 70px;
            margin-top: 10px;

            img {
                width: 29px;
            }

            .m_t_5 {
                font-size: 12px;
            }

            flex-direction: column;
            display: flex;
            align-items: center;
        }

        .outer:hover {
            background-color: #1467c8;
        }
    }

    .ordermsg {
        padding: 20px 40px 10px 30px;

        .tit {
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 30px;
        }
    }

    .ordermsg+.dialog-button {
        margin-bottom: 50px !important;
    }
</style>