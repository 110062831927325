<!-- 弹出层组件封装 -->
<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :title="title"
    :visible="true"
    :width="dialogWidth"
    @close="$emit('close')">
    <div class="dialog-div">
      <slot></slot>
      <div slot="footer" class="dialog-button">
        <el-button
          type="primary"
          style="width: 120px"
          @click="$emit('confirm')">
          确定
        </el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button @click="$emit('close')" style="width: 120px">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "myDialog",
  data: () => {
    return {
      showDialog: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: String,
      default: "",
    },
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
}
.dialog-div {
  max-height: 55vh;
  padding: 0 10px 10px 10px;
  overflow: auto;
}
.dialog-button {
  display: flex;
  justify-content: center;
}
</style>
