import request from "@/utils/request";

/* 发送登陆短信验证码 */
export function getLoginSmsCode(phoneNumber) {
  return request({
    url: "/system/sms/getLoginSmsCode/" + phoneNumber,
    method: "post",
    headers: {
      isToken: false,
    },
  });
}
/* 发送短信注册验证码 */
export function getRegisterSmsCode(phoneNumber) {
  return request({
    url: "/system/sms/getRegisterSmsCode/" + phoneNumber,
    method: "post",
    headers: {
      isToken: false,
    },
  });
}
/* 获取图形验证码 */
export function getCodeImg() {
  return request({
    url: "/captchaImage",
    headers: {
      isToken: false,
    },
    method: "get",
    timeout: 20000,
  });
}

// 短信登录
export function smsLogin(data) {
  return request({
    url: "/smsLogin",
    method: "post",
    headers: {
      isToken: false,
    },
    data: data,
  });
}
//短信注册
export function register(data) {
  return request({
    url: "/register",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 注销登录
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}

// 获取用户信息
export function getInfo() {
  return request({
    url: "/getInfo",
    method: "get",
  });
}
//获取微信二维码
export function wxQr() {
  return request({
    url: "/getWxQr",
    method: "get",
  });
}
//获取微信二维码状态
export function getWxQrStatus(scrence) {
  return request({
    url: "/getWxQrStatus/" + scrence,
    method: "get",
    isLoading: false,
  });
}
//��ȡ��¼��ά��
export function getWxqr() {
  return request({
    url: "/getWxqr",
    method: "get",
  });
}
//��ѯ�û���¼��Ϣ
export function checkLogin(data) {
  return request({
    url: "/checkLogin",
    method: "post",
    params: data,
  });
}
//����΢���û�
export function bindUser(data) {
  return request({
    url: "/bindUser",
    method: "post",
    params: data,
  });
}
