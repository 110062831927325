<template>
    <div>
        <div class="feature">
            <div class="box">
                <div class="ad">
                    <img src="../../assets/品牌保障.png" />
                    <div>
                        <div class="blue">品质保障</div>
                        <div class="gray">游轮官方授权</div>
                    </div>
                </div>
                <div class="ad">
                    <img src="../../assets/反馈更快.png" />
                    <div>
                        <div class="blue">反馈更快</div>
                        <div class="gray">第一时间短信电话通知</div>
                    </div>
                </div>
                <div class="ad">
                    <img src="../../assets/取票自由.png" />
                    <div>
                        <div class="blue">取票自由</div>
                        <div class="gray">登船武售票占取票</div>
                    </div>
                </div>
                <div class="ad">
                    <img src="../../assets/支付安全.png" />
                    <div>
                        <div class="blue">支付安全</div>
                        <div class="gray">支付宝微信网银安全</div>
                    </div>
                </div>
                <div class="ad">
                    <img src="../../assets/退改无忧.png" />
                    <div>
                        <div class="blue">退改无忧</div>
                        <div class="gray">退款方便快捷协助改签</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="foot">
            <div class="container">
                <div class="content">
                    <div>
                        <div>
                            <img src="../../assets/logo.png" class="logo" />
                        </div>
                        <div class="about">
                            <span @click="$router.push('/sys/gywm')">关于我们</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="$router.push('/sys/fwys')">服务与隐私</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="$router.push('/sys/cjwt')">常见问题</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="$router.push('/sys/fkfp')">付款发票</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="$router.push('/sys/lygl')">旅游攻略</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="$router.push('/sys/lxwm')">联系我们</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="$router.push('/sys/wzdt')">网站地图</span>
                        </div>
                        <div class="copyright">
                            {{configs.find(item=>item.keyName=="copyright").info}}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {{configs.find(item=>item.keyName=="xkz").value}}&nbsp;&nbsp;&nbsp;&nbsp;{{configs.find(item=>item.keyName=="icp").info}}&nbsp;&nbsp;{{configs.find(item=>item.keyName=="bah").info}}&nbsp;&nbsp;&nbsp;电子营业执照
                        </div>
                        <div class="address">
                            公司地址：
                            {{configs.find(item=>item.keyName=="address").value}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;官网：{{configs.find(item=>item.keyName=="gw").link}}&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div class="link">
                            <span>友情连接：<a target="_blank"
                                    :href="configs.find(item=>item.keyName=='skb').link">{{configs.find(item=>item.keyName=="skb").info}}</a></span>
                            <el-divider direction="vertical"></el-divider>
                            <span><a target="_blank"
                                    :href='configs.find(item=>item.keyName=="mgwdly").link'>{{configs.find(item=>item.keyName=="mgwdly").info}}</a></span>
                            <el-divider direction="vertical"></el-divider>
                            <span><a target="_blank"
                                    :href='configs.find(item=>item.keyName=="djyl").link'>{{configs.find(item=>item.keyName=="djyl").info}}</a></span>
                            <el-divider direction="vertical"></el-divider>
                            <span><a target="_blank"
                                    :href='configs.find(item=>item.keyName=="cjcs").link'>{{configs.find(item=>item.keyName=="cjcs").info}}</a></span>
                            <el-divider direction="vertical"></el-divider>
                            <span><a target="_blank"
                                    :href='configs.find(item=>item.keyName=="cdmoz").link'>{{configs.find(item=>item.keyName=="cdmoz").info}}</a></span>
                            <el-divider direction="vertical"></el-divider>
                            <span><a target="_blank"
                                    :href='configs.find(item=>item.keyName=="sxqbj").link'>{{configs.find(item=>item.keyName=="sxqbj").info}}</a></span>
                            <el-divider direction="vertical"></el-divider>
                            <span><a target="_blank"
                                    :href='configs.find(item=>item.keyName=="zbjd").link'>{{configs.find(item=>item.keyName=="zbjd").info}}</a></span>
                        </div>
                    </div>
                    <div class="code">
                        <div class="text_1">
                            <img src="../../assets/统一电话-icon.png" />
                            全国统一订票热线
                        </div>
                        <div class="text_2">{{configs.find(item=>item.keyName=="phone").value}}</div>

                        <div class="pic">
                            <img :src="baseUrl+configs.find(item=>item.keyName=='lxewm').img" />
                        </div>
                        <div class="text_3">扫描二维码，我们为您提供更好的服务</div>
                    </div>
                </div>
                <div class="partner">
                    <!-- <img
            v-for="i in 7"
            src="../../assets/psytz6aiidxdylvs5mtlfap4588cr61sbxf757adfc-1f84-49f9-9608-54dca5270598.png" /> -->
                    <a :href="configs.find(item=>item.keyName=='gswj').link" target="_blank">
                        <img :src="baseUrl+configs.find(item=>item.keyName=='gswj').img" />
                    </a>
                    <a :href="configs.find(item=>item.keyName=='lwba').link" target="_blank">
                        <img :src="baseUrl+configs.find(item=>item.keyName=='lwba').img" />
                    </a>
                    <a :href="configs.find(item=>item.keyName=='jyxkz').link" target="_blank">
                        <img :src="baseUrl+configs.find(item=>item.keyName=='jyxkz').img" />
                    </a>
                    <a :href="configs.find(item=>item.keyName=='4a').link" target="_blank">
                        <img :src="baseUrl+configs.find(item=>item.keyName=='4a').img" />
                    </a>
                    <a :href="configs.find(item=>item.keyName=='wx').link" target="_blank">
                        <img :src="baseUrl+configs.find(item=>item.keyName=='wx').img" />
                    </a>
                    <a :href="configs.find(item=>item.keyName=='zfb').link" target="_blank">
                        <img :src="baseUrl+configs.find(item=>item.keyName=='zfb').img" />
                    </a>
                    <a :href="configs.find(item=>item.keyName=='yl').link" target="_blank">
                        <img :src="baseUrl+configs.find(item=>item.keyName=='yl').img" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        mapGetters
    } from "vuex";
    export default {
        dicts: ["sys_site"],
        name: "HomeFooter",
        data() {
            return {
                baseUrl: process.env.VUE_APP_BASE_API
            };
        },
        computed: {
            ...mapGetters(["configs"]),
        },
    };
</script>
<style scoped lang="scss">
    /** 权益 */
    .feature {
        height: 100px;
        background-color: #f4f6f8;
        display: flex;
        align-items: center;

        .box {
            width: 1300px;
            display: flex;

            margin: 0 auto;
            justify-content: space-between;

            .ad {
                display: flex;

                justify-content: center;
                align-items: center;

                img {
                    padding-right: 10px;
                }

                .blue {
                    color: #2fa8e7;
                    font-size: 18px;
                    font-weight: bold;
                }

                .gray {
                    color: #a3a3a3;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }

    .foot {
        background-color: #17283a;
        padding-top: 50px;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;

        .container {
            max-width: 1300px;
            margin: 0 auto;
        }

        .content {
            display: flex;
            justify-content: space-between;
            padding-bottom: 58px;
            border-bottom: 1px rgba(0, 0, 0, 0.3) solid;

            .about {
                padding: 40px 0 30px 0;
                font-size: 16px;
                opacity: 0.6;

                span {
                    cursor: pointer;
                }

                span:hover {
                    color: #fff;
                }

                .el-divider {
                    background-color: rgba(255, 255, 255, 0.2);
                    margin: 0 1em;
                }
            }

            .address {
                padding-bottom: 23px;
                opacity: 0.33;
            }

            .copyright {
                opacity: 0.33;
                padding-bottom: 1em;
            }

            .logo {
                width: 340px;
            }

            .link {
                a {
                    color: #445e7a;
                    text-decoration: none;
                }

                padding-top: 15px;
                color: #445e7a;

                .el-divider {
                    background-color: rgba(255, 255, 255, 0.2);
                    margin: 0 1em;
                }
            }

            .code {
                display: flex;
                flex-direction: column;

                .text_1 {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-style: italic;
                    font-weight: 400;
                    padding-bottom: 0px;
                    color: #cccccc;
                }

                .text_2 {
                    font-size: 30px;
                    font-style: italic;
                    color: #2fa8e7;
                    font-weight: bold;
                    padding-bottom: 20px;
                }

                .pic {
                    display: flex;
                    justify-content: space-around;

                    padding-bottom: 25px;
                }

                .text_3 {
                    color: #757575;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }

        .partner {
            border-top: 1px rgba(255, 255, 255, 0.06) solid;
            padding-top: 30px;
            padding-bottom: 40px;
            display: flex;
            justify-content: center;

            img {
                margin-left: 23px;
            }
        }
    }
</style>