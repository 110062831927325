import Vue from "vue";
import App from "./App.vue";
import router from "./router"; // before
import store from "./store";
import Moment from "moment";
import scroll from "vue-seamless-scroll";

import { getDicts } from "@/api/system/dict/data";
// 字典标签组件
import DictTag from "@/components/DictTag";
// 字典数据组件
import DictData from "@/components/DictData";
/*element-ui*/
import ElementUI from "element-ui";
import("element-ui/lib/theme-chalk/index.css");
import "@/assets/css/common.css";
import _ from "lodash";
import plugins from "./plugins"; // plugins
import VueQriously from "vue-qriously";
import Meta from 'vue-meta'

Vue.config.productionTip = false;
//全局方法挂载
Vue.prototype.getDicts = getDicts;
Vue.prototype.moment = Moment;

Vue.prototype.$_ = _;
Vue.prototype._ = _;
Vue.prototype.topScroll = function () {
  if (window.scrollY == 0) {
    return;
  }
  var i = 1500;
  let time = setInterval(() => {
    window.scrollTo({
      top: (i -= 15),
      left: 0,
    });
    if (i == 0) {
      clearInterval(time);
    }
  });
};
Vue.component("DictTag", DictTag);
DictData.install();
Vue.use(ElementUI);
Vue.use(plugins);
Vue.use(scroll);
Vue.use(VueQriously);
Vue.use(Meta)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
